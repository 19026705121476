import styles from './BecomeProducer.module.scss'
import SplitSection from 'components/split-section/SplitSection'
import classNames from 'classnames'
import { Heading } from '@boltenergy-be/design-system'
import Link from 'components/link/Link'
import { routes } from 'utils/routes'
import Img from 'components/img/Img'
import useWindowSize from 'hooks/useWindowSize'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'

const BecomeProducer = () => {
  // Next router
  const { locale } = useRouter()

  // i18n
  const { t } = useTranslation('common')

  // Check window size
  const { isTablet } = useWindowSize()

  return (
    <SplitSection className={styles['become-producer']}>
      <SplitSection.Left className={classNames(styles['become-producer-left'])}>
        <Heading as="h2" variant="h3">
          {t('becomeProducer.title')}
        </Heading>

        <p>{t('becomeProducer.description')}</p>

        <Link representation="button" href={routes(locale).becomeProducer}>
          {t('becomeProducer.cta')}
        </Link>
      </SplitSection.Left>
      <SplitSection.Right className={styles['become-producer-right']}>
        <Img
          className={styles.img}
          id="become-producer"
          publicId={isTablet ? `website/home/become-producer-mobile-${locale}` : `website/home/become-producer-desktop-${locale}`}
          fill={false}
        />
      </SplitSection.Right>
    </SplitSection>
  )
}

export default BecomeProducer
